import React from 'react';
import { useQuery } from '@apollo/client';
import { CircularProgress, Alert, Box } from '@mui/material';
import { Membership, MembershipStatusEnum, MeQuery, MeQueryVariables, User } from '../../generated/graphql';
import { ME_QUERY } from '../../graphql/users';
import PendingMembership from '../../components/Memberships/PendingMembership/PendingMembership';
import NoMembership from '../../components/Memberships/NoMembership/NoMembership';
import ActiveMembershipDashboard from '../../components/Memberships/ActiveMembershipDashboard/ActiveMembershipDashboard';

const MembershipDashboardPage: React.FC = () => {
  const { data, loading, error } = useQuery<MeQuery, MeQueryVariables>(ME_QUERY);

  if (loading)
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );

  if (error) return <Alert severity="error">{error.message}</Alert>;

  const user = data?.me as User | null;

  if (!user) {
    return <Alert severity="error">User data not available.</Alert>;
  }

  const membership = user.membership as Membership | null;
  const membershipStatus = membership?.status;

  // Pass isVerified to the components
  if (membershipStatus === MembershipStatusEnum.Pending && membership) {
    return <PendingMembership membership={membership} />;
  }

  if (membershipStatus === MembershipStatusEnum.Active && user) {
    return <ActiveMembershipDashboard user={user} handleCancelMembership={() => {}} />;
  }

  // For No Membership, render the NoMembership component
  return <NoMembership user={user} />;
};

export default MembershipDashboardPage;
