import React, { useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  CircularProgress,
  Alert,
  Snackbar,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { useQuery } from '@apollo/client';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import { Link as RouterLink } from 'react-router-dom';
import { Trip, TripOrder, TripStatusEnum, Role } from '../../generated/graphql';
import { useAuth } from '../../contexts/AuthContext';
import { TRIPS_QUERY } from '../../graphql/trips';

const OrganizerDashboardPage: React.FC = () => {
  const { user } = useAuth();
  const [statusFilter, setStatusFilter] = useState<TripStatusEnum | null>(TripStatusEnum.Pending);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage] = useState<string>('');

  const [selectedTrip, setSelectedTrip] = useState<Trip | null>(null);
  const [actionsAnchorEl, setActionsAnchorEl] = useState<null | HTMLElement>(null);

  const {
    data: tripsData,
    loading: tripsLoading,
    error: tripsError,
  } = useQuery(TRIPS_QUERY, {
    variables: {
      first: rowsPerPage,
      skip: page * rowsPerPage,
      orderBy: { field: 'createdAt', direction: 'desc' } as TripOrder,
      filter: {
        status: statusFilter,
        organizerId: user?.id,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  // Guard to prevent non-organizers from accessing the page
  if (!user?.roles.includes(Role.Organizer)) {
    return (
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <Alert severity="error">You do not have permission to access this page.</Alert>
      </Container>
    );
  }

  // Handle Status Filter Change
  const handleStatusFilterChange = (event: React.MouseEvent<HTMLElement>, newStatus: TripStatusEnum | null) => {
    setStatusFilter(newStatus);
    setPage(0);
  };

  // Handle Actions Menu
  const handleActionsMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, trip: Trip) => {
    setActionsAnchorEl(event.currentTarget);
    setSelectedTrip(trip);
  };

  const handleActionsMenuClose = () => {
    setActionsAnchorEl(null);
  };

  // Pagination Handlers
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  if (tripsLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (tripsError) {
    console.error(tripsError);
    return <Alert severity="error">Error loading trips.</Alert>;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">My Trips</Typography>
        <Button variant="contained" startIcon={<AddIcon />} component={RouterLink} to={'/trip'}>
          Create New Trip
        </Button>
      </Box>

      {/* Status Filter */}
      <Box mb={2}>
        <ToggleButtonGroup
          value={statusFilter}
          exclusive
          onChange={handleStatusFilterChange}
          aria-label="trip status filter"
        >
          <ToggleButton value={TripStatusEnum.Pending} aria-label="pending trips">
            Pending
          </ToggleButton>
          <ToggleButton value={TripStatusEnum.Active} aria-label="active trips">
            Active
          </ToggleButton>
          <ToggleButton value={TripStatusEnum.Cancelled} aria-label="cancelled trips">
            Cancelled
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {/* Trips Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Price (€)</TableCell>
              <TableCell>Country</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tripsData?.trips?.edges.map(({ node }: { node: Trip }) => (
              <TableRow key={node.id}>
                <TableCell>
                  <Button component={RouterLink} to={`/trip/${node.id}`} variant="text" color="primary">
                    {node.title}
                  </Button>
                </TableCell>
                <TableCell>{node.status}</TableCell>
                <TableCell>{new Date(node.startDate).toLocaleDateString()}</TableCell>
                <TableCell>{new Date(node.endDate).toLocaleDateString()}</TableCell>
                <TableCell>€{node.price.toLocaleString()}</TableCell>
                <TableCell>{node.country}</TableCell>
                <TableCell align="center">
                  {node.status === TripStatusEnum.Pending && (
                    <IconButton onClick={(e) => handleActionsMenuOpen(e, node)} aria-label="actions">
                      <MoreVertIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {/* Pagination */}
        <TablePagination
          component="div"
          count={tripsData?.trips?.totalCount || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </TableContainer>

      {/* Actions Menu */}
      <Menu anchorEl={actionsAnchorEl} open={Boolean(actionsAnchorEl)} onClose={handleActionsMenuClose}>
        {selectedTrip?.status === TripStatusEnum.Pending && (
          <MenuItem component={RouterLink} to={`/trip/${selectedTrip.id}/edit`} onClick={handleActionsMenuClose}>
            Edit Trip
          </MenuItem>
        )}
      </Menu>

      {/* Snackbar */}
      <Snackbar open={openSnackbar} autoHideDuration={4000} onClose={handleSnackbarClose} message={snackbarMessage} />
    </Container>
  );
};

export default OrganizerDashboardPage;
