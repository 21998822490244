import React, { useState } from 'react';
import { Alert, Button, Divider, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { RESEND_VERIFICATION_EMAIL_MUTATION } from '../../../graphql/auth';
import { ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables } from '../../../generated/graphql';

interface VerificationAlertProps {
  isVerified: boolean;
  email: string;
}

const VerificationAlert: React.FC<VerificationAlertProps> = ({ isVerified, email }) => {
  const { t } = useTranslation();
  const [resendVerificationEmail, { loading: resendLoading }] = useMutation<
    ResendVerificationEmailMutation,
    ResendVerificationEmailMutationVariables
  >(RESEND_VERIFICATION_EMAIL_MUTATION);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error' | 'warning';
  }>({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleResendVerificationEmail = async () => {
    try {
      await resendVerificationEmail({
        variables: { email },
      });
      setSnackbar({
        open: true,
        message: t('verification_email_sent'),
        severity: 'success',
      });
    } catch (error: any) {
      console.error(error?.message);
      setSnackbar({
        open: true,
        message: error?.message?.includes('Wait for old verification to expire')
          ? t('verification_wait_message')
          : t('verification_send_error'),
        severity: 'error',
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return isVerified ? null : (
    <>
      <Alert severity="warning" sx={{ mb: 2 }}>
        {t('verify_email_message')}
        <Divider />
        <Button color="primary" onClick={handleResendVerificationEmail} disabled={resendLoading}>
          {t('resend_verification_email')}
        </Button>
      </Alert>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} variant="filled" sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default VerificationAlert;
