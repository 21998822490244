import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

interface MembershipActionDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  action: 'activate' | 'deactivate';
  userName: string;
}

const MembershipActionDialog: React.FC<MembershipActionDialogProps> = ({
  isOpen,
  onClose,
  onConfirm,
  action,
  userName,
}) => (
  <Dialog open={isOpen} onClose={onClose}>
    <DialogTitle>{action === 'activate' ? 'Activate' : 'Deactivate'} Membership</DialogTitle>
    <DialogContent>
      <Typography variant="body1">
        Are you sure you want to {action === 'activate' ? 'activate' : 'deactivate'} the membership for {userName}?
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Cancel
      </Button>
      <Button onClick={onConfirm} color="secondary">
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
);

export default MembershipActionDialog;
