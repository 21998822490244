import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LanguageIcon from '@mui/icons-material/Language';
import { US } from 'country-flag-icons/react/3x2';
import { SK } from 'country-flag-icons/react/3x2';
import { useTranslation } from 'react-i18next';
import { Role, User } from '../generated/graphql';

const Header: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [languageAnchorEl, setLanguageAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  // Determine if we are on the LandingPage
  const isLandingPage = location.pathname === '/';

  // Retrieve authentication status and user information from localStorage
  const isAuthenticated = !!localStorage.getItem('accessToken');
  const user: User = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!) : null;

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageMenu = (event: React.MouseEvent<HTMLElement>) => {
    setLanguageAnchorEl(event.currentTarget);
  };

  const handleLanguageClose = () => {
    setLanguageAnchorEl(null);
  };

  const handleLogout = () => {
    // Clear authentication tokens and user data
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('user');
    handleClose();
    navigate('/', { replace: true });
  };

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    handleLanguageClose();
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box sx={{ width: 250 }} role="presentation" onClick={handleDrawerToggle} onKeyDown={handleDrawerToggle}>
      <List>
        {/* Common Links */}
        <ListItem component={RouterLink} to="/">
          <ListItemText primary={t('home')} />
        </ListItem>

        {isAuthenticated ? (
          <>
            {/* Admin Links */}
            {user?.roles.includes(Role.Admin) && (
              <>
                <ListItem component={RouterLink} to="/admin/user-management">
                  <ListItemText primary={t('members')} />
                </ListItem>
                <ListItem component={RouterLink} to="/admin/trips-management">
                  <ListItemText primary={t('trips')} />
                </ListItem>
              </>
            )}
            {/* Organizer Links -> Only for Organizers, not for Admins */}
            {!user?.roles.includes(Role.Admin) && user?.roles.includes(Role.Organizer) && (
              <ListItem component={RouterLink} to="/organizer/trips-management">
                <ListItemText primary={t('organizer_trip_management')} />
              </ListItem>
            )}

            <Divider />

            {/* User Menu Items */}
            <ListItem component={RouterLink} to="/membership-dashboard">
              <ListItemText primary={t('membership_dashboard')} />
            </ListItem>
            <ListItem component={RouterLink} to="/profile">
              <ListItemText primary={t('profile')} />
            </ListItem>
            <ListItem onClick={handleLogout}>
              <ListItemText primary={t('logout')} />
            </ListItem>
          </>
        ) : (
          <>
            <ListItem component={RouterLink} to="/auth">
              <ListItemText primary={t('sign_in')} />
            </ListItem>
            <ListItem component={RouterLink} to="/auth">
              <ListItemText primary={t('sign_up')} />
            </ListItem>
          </>
        )}
      </List>
      <Divider />
      {/* Language Switcher */}
      <List>
        <ListItem onClick={() => changeLanguage('sk')}>
          <SK
            title="Slovakia"
            style={{
              width: 24,
              height: 24,
              marginRight: 8,
            }}
          />
          <ListItemText primary="Slovensky" />
        </ListItem>
        <ListItem onClick={() => changeLanguage('en')}>
          <US
            title="United States"
            style={{
              width: 24,
              height: 24,
              marginRight: 8,
            }}
          />
          <ListItemText primary="English" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <AppBar
      position={isLandingPage ? 'absolute' : 'static'}
      color={isLandingPage ? 'transparent' : 'primary'}
      elevation={isLandingPage ? 0 : 4}
      sx={{
        top: 0,
        left: 0,
        right: 0,
        zIndex: isLandingPage ? 10 : 'auto',
        color: isLandingPage ? '#fff' : 'inherit',
      }}
    >
      <Toolbar>
        {/* Logo or Home Link */}
        <Typography
          variant="h6"
          component={RouterLink}
          to="/"
          fontFamily={'CustomSurfFont, Arial, sans-serif'}
          sx={{
            textDecoration: 'none',
            color: isLandingPage ? '#fff' : 'inherit',
          }}
        >
          <img
            src={isLandingPage ? '/images/logo/just_dino_thick_white.svg' : '/images/logo/just_dino_thick_black.svg'}
            alt="Logo dino"
            style={{ height: isMobile ? '35px' : '40px', objectFit: 'cover' }}
          />
          {isMobile ? '' : <span>Level Trevel</span>}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        {isMobile ? (
          // Mobile View
          <IconButton color="inherit" aria-label="open drawer" edge="end" onClick={handleDrawerToggle}>
            <MenuIcon />
          </IconButton>
        ) : (
          // Desktop View
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* Navigation Links */}
            {isAuthenticated ? (
              <>
                {/* Admin Links */}
                {user?.roles.includes(Role.Admin) && (
                  <>
                    <Button
                      color="inherit"
                      component={RouterLink}
                      to="/admin/user-management"
                      sx={{ color: isLandingPage ? '#fff' : 'inherit' }}
                    >
                      {t('members')}
                    </Button>
                    <Button
                      color="inherit"
                      component={RouterLink}
                      to="/admin/trips-management"
                      sx={{ color: isLandingPage ? '#fff' : 'inherit' }}
                    >
                      {t('trips')}
                    </Button>
                  </>
                )}
                {/* Organizer Links -> Only for Organizers, not for Admins */}
                {!user?.roles.includes(Role.Admin) && user?.roles.includes(Role.Organizer) && (
                  <Button
                    color="inherit"
                    component={RouterLink}
                    to="/organizer/trips-management"
                    sx={{ color: isLandingPage ? '#fff' : 'inherit' }}
                  >
                    {t('organizer_trip_management')}
                  </Button>
                )}

                {/* User Icon and Menu */}
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                  sx={{ color: isLandingPage ? '#fff' : 'inherit' }}
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose} component={RouterLink} to="/membership-dashboard">
                    {t('membership_dashboard')}
                  </MenuItem>
                  <MenuItem onClick={handleClose} component={RouterLink} to="/profile">
                    {t('profile')}
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>{t('logout')}</MenuItem>
                </Menu>

                {/* Language Switcher */}
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="language"
                  aria-controls="language-menu"
                  aria-haspopup="true"
                  onClick={handleLanguageMenu}
                  color="inherit"
                  sx={{ color: isLandingPage ? '#fff' : 'inherit' }}
                >
                  <LanguageIcon />
                </IconButton>
                <Menu
                  id="language-menu"
                  anchorEl={languageAnchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(languageAnchorEl)}
                  onClose={handleLanguageClose}
                >
                  <MenuItem onClick={() => changeLanguage('sk')}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <SK title="Slovakia" style={{ width: 24, height: 24, marginRight: 8 }} />
                      <Typography variant="subtitle2">Slovensky</Typography>
                    </Box>
                  </MenuItem>
                  <MenuItem onClick={() => changeLanguage('en')}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <US title="United States" style={{ width: 24, height: 24, marginRight: 8 }} />
                      <Typography variant="subtitle2">English</Typography>
                    </Box>
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  component={RouterLink}
                  to="/auth"
                  sx={{
                    mr: 2,
                    borderRadius: '30px',
                    boxShadow: '0px 4px 6px rgba(0,0,0,0.3)',
                    color: '#000',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      backgroundColor: '#e5f2e0',
                    },
                  }}
                >
                  {t('sign_up')}
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  component={RouterLink}
                  to="/auth"
                  sx={{
                    backgroundColor: '#f6fcf2',
                    mr: 2,
                    borderRadius: '30px',
                    boxShadow: '0px 4px 6px rgba(0,0,0,0.3)',
                    color: '#000',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      backgroundColor: '#e5f2e0',
                    },
                  }}
                >
                  {t('sign_in')}
                </Button>

                {/* Language Switcher */}
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="language"
                  aria-controls="language-menu"
                  aria-haspopup="true"
                  onClick={handleLanguageMenu}
                  color="inherit"
                  sx={{ color: isLandingPage ? '#fff' : 'inherit' }}
                >
                  <LanguageIcon />
                </IconButton>
                <Menu
                  id="language-menu"
                  anchorEl={languageAnchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(languageAnchorEl)}
                  onClose={handleLanguageClose}
                >
                  <MenuItem onClick={() => changeLanguage('sk')}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <SK title="Slovakia" style={{ width: 24, height: 24, marginRight: 8 }} />
                      <Typography variant="subtitle2">Slovensky</Typography>
                    </Box>
                  </MenuItem>
                  <MenuItem onClick={() => changeLanguage('en')}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <US title="United States" style={{ width: 24, height: 24, marginRight: 8 }} />
                      <Typography variant="subtitle2">English</Typography>
                    </Box>
                  </MenuItem>
                </Menu>
              </>
            )}
          </Box>
        )}
      </Toolbar>
      {/* Mobile Drawer */}
      <Drawer
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {drawer}
      </Drawer>
    </AppBar>
  );
};

export default Header;
