import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { ME_QUERY } from '../../graphql/users';

const GoogleCallback: React.FC = () => {
  const navigate = useNavigate();

  const [getMe, { loading, error, data }] = useLazyQuery(ME_QUERY);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const accessToken = params.get('accessToken');
    const refreshToken = params.get('refreshToken');

    if (accessToken && refreshToken) {
      // Store tokens in local storage
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);

      // Fetch the current user's data
      getMe();
    } else {
      // Redirect to auth page if tokens are missing
      navigate('/auth');
    }
  }, [navigate, getMe]);

  useEffect(() => {
    if (data && data.me) {
      // Store user data in local storage
      localStorage.setItem('user', JSON.stringify(data.me));

      // Navigate to the membership dashboard
      navigate('/membership-dashboard');
    }
  }, [data, navigate]);

  if (loading) return <div>Loading...</div>;

  if (error) {
    console.error('Error fetching user data:', error);
    navigate('/auth');
    return null;
  }

  return <div>Loading...</div>;
};

export default GoogleCallback;
