import React, { useState } from 'react';
import { Button, FormControl, Grid2, IconButton, InputLabel, Select, SelectChangeEvent, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { CreateExtraInfoDto, ExtraInfo } from '../../../generated/graphql';
import RichTextEditor from '../../common/RichTextEditor/RichTextEditor';
import './ExtraInfo.scss';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';

// Define props for the ItineraryList component
interface ExtraInfoComponentProps {
  extraInfoItems: (ExtraInfo | CreateExtraInfoDto)[];
  onChange: (items: (ExtraInfo | CreateExtraInfoDto)[]) => void;
}

const ExtraInfoComponent: React.FC<ExtraInfoComponentProps> = ({ extraInfoItems, onChange }) => {
  const [extraInfo, setExtraInfo] = useState<(ExtraInfo | CreateExtraInfoDto)[]>(extraInfoItems);
  const addInfoItem = () => {
    const newItem: CreateExtraInfoDto = {
      title: '',
      description: '',
    };
    setExtraInfo([...extraInfo, newItem]); // Add new item to the array
    onChange(extraInfo);
  };

  const removeInfoItem = (deleteIndex: number) => {
    const updatedItems = extraInfo.filter((_, index) => index !== deleteIndex);
    setExtraInfo(updatedItems); // Update state with the filtered array
    onChange(extraInfo);
  };

  const handleUpdateItem = (index: number, field: keyof CreateExtraInfoDto, value: string) => {
    setExtraInfo((prevItems) => {
      let updatedItems = [...prevItems];
      updatedItems[index] = { ...updatedItems[index], [field]: value };
      onChange(updatedItems);
      return updatedItems;
    });
  };

  const titleOptions = [
    'Visa info',
    'Travel insurance',
    'Passport info',
    'Vaccination',
    'Money',
    'Time zone',
    'Weather',
    'Extra info',
  ];

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Extra info
      </Typography>

      {/* Input fields for adding a new extraInfo item */}
      <Grid2 container spacing={2}>
        {extraInfo &&
          extraInfo.map((item, itemIndex) => (
            <Grid2 size={12} key={itemIndex} className="extra-info-element">
              <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 12, md: 6 }} sx={{ minWidth: 120 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Title
                  </Typography>
                  <FormControl fullWidth>
                    <InputLabel id="select-label">Title</InputLabel>
                    <Select
                      labelId="select-label"
                      id="demo-simple-select"
                      value={item.title}
                      label="Title"
                      onChange={(event: SelectChangeEvent) => handleUpdateItem(itemIndex, 'title', event.target.value)}
                    >
                      {titleOptions &&
                        titleOptions.map((title, titleIndex) => <MenuItem value={title} key={titleIndex}>{title}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid2>

                <Grid2 size={{ xs: 12, md: 6 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Description
                  </Typography>
                  <RichTextEditor
                    content={item.description}
                    onChange={(desc: string) => handleUpdateItem(itemIndex, 'description', desc)}
                    index={itemIndex}
                  />
                </Grid2>
              </Grid2>

              <IconButton aria-label="delete" sx={{ position: 'absolute', top: 2, right: 2 }} onClick={() => removeInfoItem(itemIndex)}>
                <CloseIcon />
              </IconButton>
            </Grid2>
          ))}
      </Grid2>

      <Grid2 container spacing={1} size={12} sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
        <Button startIcon={<Add />} color="primary" variant="contained" className="icon-button" onClick={addInfoItem}>
          Add extra info
        </Button>
      </Grid2>
    </div>
  );
};

export default ExtraInfoComponent;
