import React, { useState } from 'react';
import { Box, Grid, Typography, Button, CircularProgress, Alert, Card, CardContent, Avatar, Chip } from '@mui/material';
import ProfileEditModal from '../../components/User/ProfileEditModal';
import EditIcon from '@mui/icons-material/Edit';
import { useAuth } from '../../contexts/AuthContext';
import VerificationAlert from '../../components/User/VerificationAlert/VerificationAlert';
import LanguageIcon from '@mui/icons-material/Language';
import iso6391 from 'iso-639-1';
import countryList from 'country-list';
import ReactCountryFlag from 'react-country-flag';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkIcon from '@mui/icons-material/Link';

const MePage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user, loading, error, refetch } = useAuth();

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error">
        Error fetching profile data.
        <Button onClick={() => refetch()} variant="outlined" size="small" sx={{ ml: 1 }}>
          Retry
        </Button>
      </Alert>
    );
  }

  if (!user) {
    return <Alert severity="info">No profile data available.</Alert>;
  }

  const openEditModal = () => {
    setIsModalOpen(true);
  };

  const countryCodeToName: Record<string, string> = countryList.getData().reduce(
    (acc: Record<string, string>, country) => {
      acc[country.code] = country.name;
      return acc;
    },
    {} as Record<string, string>
  );

  const getCountryName = (code: string) => countryCodeToName[code] || code;
  const getLanguageName = (code: string) => iso6391.getName(code) || code;

  const getSocialIcon = (type: string) => {
    switch (type.toLowerCase()) {
      case 'linkedin':
        return <LinkedInIcon />;
      case 'twitter':
        return <TwitterIcon />;
      case 'facebook':
        return <FacebookIcon />;
      case 'instagram':
        return <InstagramIcon />;
      case 'github':
        return <GitHubIcon />;
      default:
        return <LinkIcon />;
    }
  };

  return (
    <Box
      sx={{
        p: { xs: 2, md: 3 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <VerificationAlert isVerified={user.isVerified} email={user.email} />

      {/* Profile Header */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          width: '100%',
          maxWidth: 800,
          mb: 4,
        }}
      >
        <Avatar
          src={user?.imageUrl || '/images/default_avatar.png'}
          alt={`${user.firstname} ${user.lastname}`}
          sx={{ width: 100, height: 100, mr: { md: 4 }, mb: { xs: 2, md: 0 } }}
        />
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" gutterBottom>
            {user.firstname && user.lastname ? `${user.firstname} ${user.lastname}` : user.email}
          </Typography>
          {user.position && (
            <Typography variant="h6" color="textSecondary">
              {user.position}
            </Typography>
          )}
          <Typography variant="body1" color="textSecondary">
            {user.email}
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={openEditModal}
          startIcon={<EditIcon />}
          sx={{ mt: { xs: 2, md: 0 } }}
        >
          Edit Profile
        </Button>
      </Box>

      {/* Public Profile Section */}
      <Card sx={{ maxWidth: 800, width: '100%', mb: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Public Profile
          </Typography>
          <Grid container spacing={2}>
            {/* About Me */}
            {user.description && (
              <Grid item xs={12}>
                <Typography variant="subtitle1">About Me</Typography>
                <Typography variant="body1">{user.description}</Typography>
              </Grid>
            )}
            {/* Languages Spoken */}
            {user.languagesSpoken && user.languagesSpoken.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="subtitle1">Languages Spoken</Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                  {user.languagesSpoken.map((code) => (
                    <Chip key={code} label={getLanguageName(code)} icon={<LanguageIcon />} />
                  ))}
                </Box>
              </Grid>
            )}

            {/* Countries Visited */}
            {user.countriesVisited && user.countriesVisited.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="subtitle1">Countries Visited</Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                  {user.countriesVisited.map((code) => (
                    <Chip
                      key={code}
                      label={getCountryName(code)}
                      icon={<ReactCountryFlag countryCode={code} svg style={{ width: '1em', height: '1em' }} />}
                    />
                  ))}
                </Box>
              </Grid>
            )}

            {/* Social Links */}
            {user.socialMediaLinks && (
              <Grid item xs={12}>
                <Typography variant="subtitle1">Social Links</Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 1 }}>
                  {user.socialMediaLinks.map((link) => (
                    <Button
                      key={link.type}
                      variant="outlined"
                      color="primary"
                      href={link.url}
                      target="_blank"
                      startIcon={getSocialIcon(link.type)}
                    >
                      {link.type.charAt(0).toUpperCase() + link.type.slice(1)}
                    </Button>
                  ))}
                </Box>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>

      {/* Profile Details */}
      <Card sx={{ maxWidth: 800, width: '100%' }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Profile Details
          </Typography>
          <Grid container spacing={2}>
            {/* First Name */}
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">First Name</Typography>
              <Typography variant="body1">{user.firstname || 'No data'}</Typography>
            </Grid>
            {/* Last Name */}
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">Last Name</Typography>
              <Typography variant="body1">{user.lastname || 'No data'}</Typography>
            </Grid>
            {/* Phone */}
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">Phone</Typography>
              <Typography variant="body1">{user.phone || 'No data'}</Typography>
            </Grid>
            {/* Birth Date */}
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">Birth Date</Typography>
              <Typography variant="body1">
                {user.birthDate ? new Date(user.birthDate).toLocaleDateString() : 'No data'}
              </Typography>
            </Grid>
            {/* Permanent Address */}
            <Grid item xs={12}>
              <Typography variant="subtitle1">Permanent Address</Typography>
              <Typography variant="body1">{user.permanentAddress || 'No data'}</Typography>
            </Grid>
            {/* Contact Address */}
            <Grid item xs={12}>
              <Typography variant="subtitle1">Contact Address</Typography>
              <Typography variant="body1">{user.contactAddress || 'No data'}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Profile Edit Modal */}
      <ProfileEditModal open={isModalOpen} onClose={() => setIsModalOpen(false)} user={user} />
    </Box>
  );
};

export default MePage;
