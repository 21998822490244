import React, { useEffect, useState } from 'react';
import { Container, Typography, CircularProgress, Alert, Button } from '@mui/material';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { VERIFY_EMAIL_MUTATION } from '../../graphql/auth';
import { VerifyEmailMutation, VerifyEmailMutationVariables } from '../../generated/graphql';
import { useAuth } from '../../contexts/AuthContext';

const EmailVerificationPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get('token');
  const [verificationStatus, setVerificationStatus] = useState<'loading' | 'success' | 'error'>('loading');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { refetch } = useAuth();

  const [verifyEmail] = useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VERIFY_EMAIL_MUTATION);

  useEffect(() => {
    if (!token) {
      setVerificationStatus('error');
      setErrorMessage('Verification token is missing.');
      return;
    }

    const verify = async () => {
      try {
        const { data } = await verifyEmail({ variables: { token } });
        if (data && data.verifyEmail) {
          // Store tokens in localStorage
          localStorage.setItem('accessToken', data.verifyEmail.accessToken);
          localStorage.setItem('refreshToken', data.verifyEmail.refreshToken);

          // Refetch the user data
          await refetch();

          setVerificationStatus('success');
        } else {
          setVerificationStatus('error');
          setErrorMessage('Verification failed.');
        }
      } catch (error: any) {
        console.error(error);
        setVerificationStatus('error');
        setErrorMessage(error.message || 'An error occurred during verification.');
      }
    };

    verify();
  }, [token, verifyEmail, refetch]);

  const handleProceed = () => {
    navigate('/');
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 8, textAlign: 'center' }}>
      {verificationStatus === 'loading' && (
        <>
          <Typography variant="h5" gutterBottom>
            Verifying your email...
          </Typography>
          <CircularProgress />
        </>
      )}
      {verificationStatus === 'success' && (
        <>
          <Typography variant="h4" gutterBottom>
            Email Verified Successfully!
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Thank you for verifying your email. You are now logged in.
          </Typography>
          <Button variant="contained" color="primary" onClick={handleProceed}>
            Go to Home
          </Button>
        </>
      )}
      {verificationStatus === 'error' && (
        <>
          <Typography variant="h4" gutterBottom>
            Email Verification Failed
          </Typography>
          <Alert severity="error" sx={{ mb: 2 }}>
            {errorMessage}
          </Alert>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Please contact support if you continue to experience issues.
          </Typography>
          <Button variant="contained" color="primary" onClick={() => navigate('/')}>
            Go to Home
          </Button>
        </>
      )}
    </Container>
  );
};

export default EmailVerificationPage;
