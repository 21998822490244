// src/components/Admin/UserManagement/UsersTable.tsx

import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  IconButton,
  TablePagination,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { User, UsersQuery } from '../../../../generated/graphql';

interface UsersTableProps {
  usersData: UsersQuery | undefined;
  page: number;
  rowsPerPage: number;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleActionsMenuOpen: (event: React.MouseEvent<HTMLButtonElement>, user: User) => void;
  handleOpenMembershipReviewModal: (user: User) => void;
  handleOpenMembershipActionDialog: (user: User, action: 'activate' | 'deactivate') => void;
}

const UsersTable: React.FC<UsersTableProps> = ({
  usersData,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  handleActionsMenuOpen,
  handleOpenMembershipReviewModal,
  handleOpenMembershipActionDialog,
}) => (
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Phone</TableCell>
          <TableCell>Address</TableCell>
          <TableCell>Roles</TableCell>
          <TableCell>Membership Status</TableCell>
          <TableCell>Requested At</TableCell>
          <TableCell align="right">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {usersData?.users?.edges?.map(({ node }) => (
          <TableRow key={node.id}>
            <TableCell>
              {node.firstname && node.lastname ? (
                <Button component={RouterLink} to={`/user/${node.id}`} variant="text" color="primary">
                  {`${node.firstname} ${node.lastname}`}
                </Button>
              ) : (
                'No Name'
              )}
            </TableCell>
            <TableCell>{node.email || 'N/A'}</TableCell>
            <TableCell>{node.phone || 'N/A'}</TableCell>
            <TableCell>{node.contactAddress || 'N/A'}</TableCell>
            <TableCell>{node.roles?.join(', ') || 'USER'}</TableCell>
            <TableCell>{node.membership?.status || 'N/A'}</TableCell>
            <TableCell>
              {node.membership?.createdAt ? new Date(node.membership.createdAt).toLocaleDateString() : 'N/A'}
            </TableCell>
            <TableCell align="right">
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                {/* Membership actions */}
                {node.membership ? (
                  node.membership.status === 'PENDING' ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleOpenMembershipReviewModal(node as User)}
                      startIcon={<CheckCircleIcon />}
                      sx={{ mr: 1 }}
                    >
                      Review
                    </Button>
                  ) : node.membership.status === 'ACTIVE' ? (
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleOpenMembershipActionDialog(node as User, 'deactivate')}
                      startIcon={<CancelIcon />}
                      sx={{ mr: 1 }}
                    >
                      Deactivate
                    </Button>
                  ) : node.membership.status === 'REJECTED' ? (
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => handleOpenMembershipActionDialog(node as User, 'activate')}
                      startIcon={<CheckCircleIcon />}
                      sx={{ mr: 1 }}
                    >
                      Activate
                    </Button>
                  ) : null
                ) : null}
                {/* Actions Menu */}
                <IconButton onClick={(e) => handleActionsMenuOpen(e, node as User)} aria-label="more actions">
                  <MoreVertIcon />
                </IconButton>
              </Box>
            </TableCell>
          </TableRow>
        ))}
        {/* Handle Empty State */}
        {usersData?.users?.edges?.length === 0 && (
          <TableRow>
            <TableCell colSpan={8} align="center">
              No users found.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>

    {/* Pagination */}
    <TablePagination
      component="div"
      count={usersData?.users?.totalCount || 0}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelRowsPerPage="Rows per page"
    />
  </TableContainer>
);

export default UsersTable;
