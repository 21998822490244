import React, { useState } from 'react';
import { Button, TextField, Container, Typography, Alert, Box, Paper } from '@mui/material';
import { useMutation } from '@apollo/client';
import { REQUEST_PASSWORD_RESET_MUTATION } from '../../graphql/auth';
import { useNavigate } from 'react-router-dom';

const ForgotPasswordPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [requestPasswordReset, { loading }] = useMutation(REQUEST_PASSWORD_RESET_MUTATION);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage(null);
    try {
      await requestPasswordReset({ variables: { email } });
      setSuccessMessage('If that email address is in our system, we have sent a password reset link.');
    } catch (err: any) {
      setError(err.message);
    }
  };

  const handleBackToLogin = () => {
    navigate('/auth');
  };

  return (
    <Container maxWidth="xs" sx={{ mt: 8 }}>
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Box textAlign="center" mb={3}>
          <Typography variant="h4" component="h1">
            Forgot Password
          </Typography>
          <Typography variant="subtitle1">
            Enter your email address and we'll send you a link to reset your password.
          </Typography>
        </Box>
        {error && <Alert severity="error">{error}</Alert>}
        {successMessage && <Alert severity="success">{successMessage}</Alert>}
        <form onSubmit={handleSubmit} noValidate>
          <TextField
            fullWidth
            label="Email"
            margin="normal"
            variant="outlined"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            autoComplete="email"
          />
          <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 2 }} disabled={loading}>
            Send Reset Link
          </Button>
        </form>
        <Box mt={2} textAlign="center">
          <Button onClick={handleBackToLogin}>Back to Login</Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default ForgotPasswordPage;
