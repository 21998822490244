import React, { useState } from 'react';
import { Button, TextField, Container, Typography, Alert, Box, Paper } from '@mui/material';
import { useMutation } from '@apollo/client';
import { RESET_PASSWORD_MUTATION } from '../../graphql/auth';
import { useNavigate, useSearchParams } from 'react-router-dom';

const ResetPasswordPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') || '';
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD_MUTATION);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage(null);
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }
    try {
      await resetPassword({ variables: { token, newPassword: password } });
      setSuccessMessage('Your password has been reset successfully.');
    } catch (err: any) {
      setError(err.message);
    }
  };

  const handleBackToLogin = () => {
    navigate('/auth');
  };

  return (
    <Container maxWidth="xs" sx={{ mt: 8 }}>
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Box textAlign="center" mb={3}>
          <Typography variant="h4" component="h1">
            Reset Password
          </Typography>
          <Typography variant="subtitle1">Enter your new password below.</Typography>
        </Box>
        {error && <Alert severity="error">{error}</Alert>}
        {successMessage && <Alert severity="success">{successMessage}</Alert>}
        <form onSubmit={handleSubmit} noValidate>
          <TextField
            fullWidth
            label="New Password"
            type="password"
            margin="normal"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            autoComplete="new-password"
          />
          <TextField
            fullWidth
            label="Confirm New Password"
            type="password"
            margin="normal"
            variant="outlined"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            autoComplete="new-password"
          />
          <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 2 }} disabled={loading}>
            Reset Password
          </Button>
        </form>
        <Box mt={2} textAlign="center">
          <Button onClick={handleBackToLogin}>Back to Login</Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default ResetPasswordPage;
