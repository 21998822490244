import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Stepper, Step, StepLabel, Alert } from '@mui/material';
import MembershipForm from './MembershipForm';
import ReviewDetails from './ReviewDetails';
import UploadSignedDocument from './UploadSignedDocument';
import Confirmation from './Confirmation';
import { useMutation, useQuery } from '@apollo/client';
import {
  MEMBERSHIP_REQUEST_MUTATION,
  SUBMIT_MEMBERSHIP_REQUEST_MUTATION,
  MY_MEMBERSHIP_QUERY,
} from '../../../graphql/membership';
import { Membership, MembershipStatusEnum } from '../../../generated/graphql';

const steps = ['Fill Details', 'Review & Download PDF', 'Upload Signed Document', 'Confirmation'];

const MultiStepForm: React.FC = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState<Partial<Membership>>({});
  const [signedDocumentKey, setSignedDocumentKey] = useState<string | null>(null);

  const { data, loading: queryLoading, error: queryError } = useQuery(MY_MEMBERSHIP_QUERY);

  const [membershipRequest, { loading: mutationLoading, error: mutationError }] = useMutation(
    MEMBERSHIP_REQUEST_MUTATION,
    {
      refetchQueries: [{ query: MY_MEMBERSHIP_QUERY }],
      onCompleted: (data) => {
        if (data && data.membershipRequest) {
          setFormData(data.membershipRequest);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      },
    }
  );

  const [submitMembershipRequest, { loading: submitLoading, error: submitError }] = useMutation(
    SUBMIT_MEMBERSHIP_REQUEST_MUTATION,
    {
      refetchQueries: [{ query: MY_MEMBERSHIP_QUERY }],
      onCompleted: (data) => {
        if (data && data.submitMembershipRequest) {
          setFormData(data.submitMembershipRequest);
        }
        navigate('/membership-dashboard'); // TODO
      },
    }
  );

  useEffect(() => {
    if (data && data.myMembership) {
      setFormData(data.myMembership);
      if (
        data.myMembership.status === MembershipStatusEnum.Pending ||
        data.myMembership.status === MembershipStatusEnum.Active
      ) {
        // If membership is already pending or active, skip to the Confirmation step
        setActiveStep(3);
      } else if (data.myMembership.status === MembershipStatusEnum.Initiated) {
        // If membership is initiated, start from the Review & Download PDF step
        setActiveStep(1);
      }
    }
  }, [data]);

  const handleNext = async (values?: Partial<Membership>) => {
    if (activeStep === 0 && values) {
      // Step 0: Fill Details
      setFormData(values);

      try {
        await membershipRequest({
          variables: {
            data: values,
          },
        });

        // setActiveStep is handled in onCompleted
      } catch (error) {
        console.error('Error in membershipRequest mutation:', error);
      }
    } else if (activeStep === 1) {
      // Step 1: Review & Download PDF
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep === 2) {
      // Step 2: Upload Signed Document
      if (!signedDocumentKey) {
        alert('Please upload the signed document.');
        return;
      }

      try {
        await submitMembershipRequest({
          variables: {
            signedDocumentKey,
          },
        });

        // setActiveStep is handled in onCompleted
      } catch (error) {
        console.error('Error in submitMembershipRequest mutation:', error);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  if (queryLoading) return <div>Loading...</div>;

  if (queryError && queryError.message !== 'No membership found') {
    return <Alert severity="error">{queryError.message}</Alert>;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 2 }}>
        {activeStep === 0 && <MembershipForm initialValues={formData} onNext={handleNext} loading={mutationLoading} />}
        {activeStep === 1 && <ReviewDetails formData={formData} onBack={handleBack} onNext={handleNext} />}
        {activeStep === 2 && (
          <UploadSignedDocument
            onBack={handleBack}
            onNext={handleNext}
            setSignedDocumentKey={setSignedDocumentKey}
            signedDocumentKey={signedDocumentKey}
            loading={submitLoading}
          />
        )}
        {activeStep === 3 && <Confirmation formData={formData} />}
      </Box>
      {/* Display Errors */}
      {(mutationError || submitError) && (
        <Box sx={{ mt: 2 }}>
          <Alert severity="error">{mutationError?.message || submitError?.message}</Alert>
        </Box>
      )}
    </Box>
  );
};

export default MultiStepForm;
