import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Grid,
  CircularProgress,
  Box,
  Avatar,
  IconButton,
  Tooltip,
  Typography,
  MenuItem,
  Snackbar,
  Alert,
  FormControlLabel,
  Switch,
  Autocomplete,
} from '@mui/material';
import { useMutation } from '@apollo/client';
import {
  SocialMediaLink,
  SocialMediaType,
  UpdateUserDocument,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  User,
} from '../../generated/graphql';
import ImageUpload from '../ImageUpload/ImageUpload';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import countryList from 'country-list';
import iso6391 from 'iso-639-1';

interface Country {
  name: string;
  code: string;
}

interface ProfileEditModalProps {
  open: boolean;
  onClose: () => void;
  user?: User | null;
}

const ProfileEditModal: React.FC<ProfileEditModalProps> = ({ open, onClose, user }) => {
  const countryOptions: Country[] = countryList.getData().map((country: Country) => ({
    name: country.name,
    code: country.code,
  }));

  const languageOptions = iso6391.getAllCodes().map((code) => ({
    label: iso6391.getName(code),
    value: code,
  }));

  const [formData, setFormData] = useState<{
    firstname: string;
    lastname: string;
    phone: string;
    birthDate: string;
    permanentAddress: string;
    contactAddress: string;
    isEmailSubscribed: boolean;
    position: string;
    description: string;
    countriesVisited: string[];
    languagesSpoken: string[];
    socialMediaLinks: SocialMediaLink[];
  }>({
    firstname: user?.firstname || '',
    lastname: user?.lastname || '',
    phone: user?.phone || '',
    birthDate: user?.birthDate || '',
    permanentAddress: user?.permanentAddress || '',
    contactAddress: user?.contactAddress || '',
    isEmailSubscribed: user?.isEmailSubscribed ?? true,
    position: user?.position || '',
    description: user?.description || '',
    countriesVisited: user?.countriesVisited || [],
    languagesSpoken: user?.languagesSpoken || [],
    socialMediaLinks: user?.socialMediaLinks || [],
  });

  const [imageUrl, setImageUrl] = useState<string | null>(user?.imageUrl || null);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [updateUser] = useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, {
    onCompleted: () => {
      onClose();
    },
    onError: (error) => {
      console.error('Error updating profile:', error);
    },
  });

  useEffect(() => {
    if (user) {
      setFormData((prevData) => ({
        ...prevData,
        firstname: user.firstname || '',
        lastname: user.lastname || '',
        phone: user.phone || '',
        birthDate: user.birthDate || '',
        permanentAddress: user.permanentAddress || '',
        contactAddress: user.contactAddress || '',
        isEmailSubscribed: user.isEmailSubscribed ?? true,
        position: user.position || '',
        description: user.description || '',
        countriesVisited: user.countriesVisited || [],
        languagesSpoken: user.languagesSpoken || [],
        socialMediaLinks: user.socialMediaLinks || [],
      }));
      setImageUrl(user.imageUrl || null);
    }
  }, [user]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    if (name === 'countriesVisited' || name === 'languagesSpoken') {
      setFormData({ ...formData, [name]: value.split(',').map((item) => item.trim()) });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSocialLinkChange = (index: number, field: 'type' | 'url', value: string) => {
    const updatedLinks: SocialMediaLink[] = [...formData.socialMediaLinks];
    updatedLinks[index] = {
      ...updatedLinks[index],
      [field]: field === 'type' ? (value as SocialMediaType) : value,
    };

    setFormData({
      ...formData,
      socialMediaLinks: updatedLinks,
    });
  };

  const addSocialLinkField = () => {
    setFormData({
      ...formData,
      socialMediaLinks: [...formData.socialMediaLinks, { type: SocialMediaType.Facebook, url: '' }],
    });
  };

  const removeSocialLinkField = (index: number) => {
    const updatedLinks = [...formData.socialMediaLinks];
    updatedLinks.splice(index, 1);
    setFormData({
      ...formData,
      socialMediaLinks: updatedLinks,
    });
  };

  const hasDuplicateSocialMediaTypes = (links: SocialMediaLink[]): boolean => {
    const types = links.map((link) => link.type);
    return types.length !== new Set(types).size;
  };

  const handleSave = async () => {
    if (hasDuplicateSocialMediaTypes(formData.socialMediaLinks)) {
      setError('Please remove duplicate social media platforms before saving');
      return;
    }

    setIsSaving(true);
    try {
      const cleanedSocialMediaLinks = formData.socialMediaLinks.map(({ type, url }) => ({
        type,
        url,
      }));

      await updateUser({
        variables: {
          data: {
            ...formData,
            imageUrl: imageUrl,
            socialMediaLinks: cleanedSocialMediaLinks,
          },
        },
      });
    } catch (error) {
      setError('Error updating profile. Please try again.');
      console.error('Error updating profile:', error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        aria-labelledby="edit-profile-dialog"
        PaperProps={{
          sx: {
            width: { xs: '100%', sm: 'auto' },
            m: { xs: 1, sm: 'auto' },
          },
        }}
      >
        <DialogTitle id="edit-profile-dialog">Edit Profile</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {/* Profile Image */}
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Avatar
                src={imageUrl || '/images/default_avatar.png'}
                alt="Profile Image"
                sx={{ width: 100, height: 100, mx: 'auto', mb: 2 }}
              />
              <ImageUpload onUploadSuccess={(uploadedImageUrl) => setImageUrl(uploadedImageUrl)} />
            </Grid>
            {/* First Name */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="First Name"
                name="firstname"
                value={formData.firstname}
                onChange={handleInputChange}
                fullWidth
                required
              />
            </Grid>
            {/* Last Name */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Last Name"
                name="lastname"
                value={formData.lastname}
                onChange={handleInputChange}
                fullWidth
                required
              />
            </Grid>
            {/* Phone */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                fullWidth
                required
              />
            </Grid>
            {/* Birth Date */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Birth Date"
                name="birthDate"
                value={formData.birthDate}
                onChange={handleInputChange}
                fullWidth
                type="date"
                InputLabelProps={{ shrink: true }}
                required
              />
            </Grid>
            {/* Permanent Address */}
            <Grid item xs={12}>
              <TextField
                label="Permanent Address"
                name="permanentAddress"
                value={formData.permanentAddress}
                onChange={handleInputChange}
                fullWidth
                required
              />
            </Grid>
            {/* Contact Address */}
            <Grid item xs={12}>
              <TextField
                label="Contact Address"
                name="contactAddress"
                value={formData.contactAddress}
                onChange={handleInputChange}
                fullWidth
                helperText="Optional"
              />
            </Grid>
            {/* Email Subscription Toggle */}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.isEmailSubscribed}
                    onChange={(e) => setFormData({ ...formData, isEmailSubscribed: e.target.checked })}
                    name="isEmailSubscribed"
                  />
                }
                label="Receive email notifications"
              />
            </Grid>
            {/* Guide Title */}
            <Grid item xs={12}>
              <TextField
                label="Guide Title"
                name="position"
                value={formData.position}
                onChange={handleInputChange}
                fullWidth
                helperText="E.g., 'Mountain Guide', 'Surf Instructor'"
              />
            </Grid>
            {/* About Me */}
            <Grid item xs={12}>
              <TextField
                label="About Me"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                fullWidth
                multiline
                rows={4}
              />
            </Grid>
            {/* Languages Spoken */}
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={languageOptions}
                getOptionLabel={(option) => option.label}
                value={languageOptions.filter((option) => formData.languagesSpoken.includes(option.value))}
                onChange={(event, newValue) => {
                  setFormData({
                    ...formData,
                    languagesSpoken: newValue.map((option) => option.value),
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Languages Spoken" placeholder="Select languages" />
                )}
              />
            </Grid>

            {/* Countries Visited */}
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={countryOptions}
                getOptionLabel={(option: Country) => option.name}
                value={countryOptions.filter((option) => formData.countriesVisited.includes(option.code))}
                onChange={(event, newValue: Country[]) => {
                  setFormData({
                    ...formData,
                    countriesVisited: newValue.map((option) => option.code),
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Countries Visited" placeholder="Select countries" />
                )}
              />
            </Grid>
            {/* Social Links */}
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="subtitle1">Social Links</Typography>
                <Tooltip title="Add Social Link">
                  <IconButton onClick={addSocialLinkField} sx={{ ml: 1 }}>
                    <AddCircleOutlineIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              {formData.socialMediaLinks.map((link, index) => (
                <Grid container spacing={1} key={index} sx={{ mt: 1 }}>
                  <Grid item xs={5}>
                    <TextField
                      select
                      label="Platform"
                      value={link.type}
                      onChange={(e) => handleSocialLinkChange(index, 'type', e.target.value)}
                      fullWidth
                    >
                      {Object.values(SocialMediaType).map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="URL"
                      value={link.url}
                      onChange={(e) => handleSocialLinkChange(index, 'url', e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton onClick={() => removeSocialLinkField(index)}>
                      <AddCircleOutlineIcon sx={{ transform: 'rotate(45deg)' }} />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', p: 2 }}>
          <Button onClick={onClose} color="secondary" disabled={isSaving}>
            Cancel
          </Button>
          <Box sx={{ position: 'relative' }}>
            <Button onClick={handleSave} color="primary" variant="contained" disabled={isSaving} sx={{ minWidth: 120 }}>
              {isSaving ? 'Saving...' : 'Save Changes'}
            </Button>
            {isSaving && (
              <CircularProgress
                size={24}
                sx={{
                  color: 'primary.main',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ProfileEditModal;
